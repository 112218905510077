<template lang="html">
  <div>
    <div class="title-area mt-s">
      <h2 class="title-b">설정 / 단말기 로그</h2>
    </div>
    <div class="search-area mt-s">
      <div class="search-item">
        <t-rich-select
          v-if="getUserRole === 'master'"
          :options="companyList"
          class="border border-solid rounded-md py-1 mx-2 text-gray-700 border-gray-300 outline-none w-52"
          textAttribute="companyName"
          valueAttribute="companyGuid"
          :hideSearchBox="true"
          v-model="companySearchGuid"
          @change="selectCompany"
          placeholder="고객사/운용부서"
          :clearable="true"
          noResultsText="등록된 고객사가 없습니다"
        >
        </t-rich-select>

        <t-rich-select
          :options="siteList"
          class="border border-solid rounded-md py-1 mx-2 text-gray-700 border-gray-300 outline-none w-52"
          textAttribute="siteName"
          valueAttribute="siteGuid"
          :hideSearchBox="true"
          v-model="siteSearchGuid"
          @change="selectSite"
          placeholder="관리구역"
          :clearable="true"
          noResultsText="등록된 관리구역이 없습니다."
        >
        </t-rich-select>

        <t-rich-select
          :options="mapList"
          class="border border-solid rounded-md py-1 mx-2 text-gray-700 border-gray-300 outline-none w-52"
          textAttribute="mapName"
          valueAttribute="mapGuid"
          :hideSearchBox="true"
          v-model="mapSearchGuid"
          placeholder="상세위치"
          :clearable="true"
          noResultsText="등록된 상세위치가 없습니다"
        >
        </t-rich-select>

        <t-rich-select
          :options="eventSearchList"
          class="border border-solid rounded-md py-1 mx-2 text-gray-700 border-gray-300 outline-none w-52"
          textAttribute="value"
          valueAttribute="id"
          :hideSearchBox="true"
          v-model="eventSearchValue"
          placeholder="이벤트"
          :clearable="true"
        >
        </t-rich-select>

        <input class="element-input w-100 outline-none" type="text" v-model="deviceSearchID" placeholder="단말기 아이디">

        <button type="button" class="element-btn bg-blue w-150" @click="searchDeviceLogList()">검색</button>
      </div>
      <div class="btn-item">
      </div>
    </div>
    
    <div class="table-head mt-xs">
      <div class="total-item">
        <span class="total">Total {{totalCount}}</span>
      </div>
      <div class="btn-item">
      </div>
    </div>

    <div class="table-a">
      <table>
        <colgroup>
          <col style="width:60px">
          <col style="width:150px">
          <col style="width:100px">
          <col style="width:150px">
          <col style="width:150px">
          <col style="width:120px">
          <col style="width:120px">
          <col style="width:120px">
        </colgroup>
        <thead>
        <tr>
          <th scope="col">No.</th>
          <th scope="col">등록일자</th>
          <th scope="col">단말ID</th>
          <th scope="col">단말기명</th>
          <th scope="col">고객사</th>
          <th scope="col">관리구역</th>
          <th scope="col">상세위치</th>
          <th scope="col">이벤트</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(dItem, idx) in deviceLogList" :key="idx">
          <td>{{ totalCount - idx - (limit * ( currentPage - 1 )) }}</td>
          <td>{{ dItem.eventDate | moment('YYYY-MM-DD HH:mm:ss')}}</td>
          <td>{{ dItem.deviceID }}</td>
          <td><span class="txt-blue">{{ dItem.deviceName }}</span></td>
          <td>{{ dItem.companyName }}</td>
          <td>{{ dItem.siteName }}</td>
          <td>{{ dItem.mapName }}</td>
          <td>{{ eventList[dItem.eventType] }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <paging-component :totalCount="totalCount" :limit="limit" :currentPage="currentPage" @chgCurrentPage="chgCurrentPage"></paging-component>

  </div>
</template>

<script>
import PagingComponent from "@/components/elements/paging"
import { requestApi } from "@/utils/api"
import { mapGetters, mapActions } from "vuex"
import { eventList, eventSearchList } from "@/references/config"

export default {
  props: {
    searchDeviceGuid: {type:String,default:''}
  },
  async created () {
    if(this.searchDeviceGuid != '') {
      await this.getDeviceRelation ()
    }



    this.companyGuid = this.getCompanyGuid
    await this.getCompanyListAll();
    if(this.getCompanyGuid != "0") {
      this.companySearchGuid = this.getCompanyGuid
      await this.getSiteListAll()
    }
    await this.getDeviceLogList();


    
  },
  components: {
    PagingComponent
  },
  computed: {
    ...mapGetters(["getIsOverlay","getUserRole","getCompanyGuid"]),
  },
  data () {
    return {
      eventList,
      eventSearchList,
      limit: 15,
      currentPage: 1,
      totalCount: 0,
      deviceLogList: [],

      selectedItem: [],
      isCheckAll: false,

      companyGuid:'',
      siteGuid:'',
      mapGuid:'',
      deviceGuid: '',
      deviceName:'',
      deviceStatus:'',
      eventType: '',

      companyList: [],
      companySearchGuid: '',
      siteList: [],
      siteSearchGuid: '',
      mapList: [],
      mapSearchGuid: '',

      deviceSearchID: '',
      eventSearchValue: '',
      deviceInfo: {}
    }
  },
  methods: {
    ...mapActions(["chgIsOverlay","chgIsAlert"]),
    selectCompany () {
      this.siteSearchGuid = ''
      this.getSiteListAll()
    },
    selectSite () {
      this.mapSearchGuid = ''
      this.getMapListAll()
    },

    selectMap () {
    },

    registDevice () {
      this.$router.push({name:"DeviceRegist"})
    },
    updateDevice (guid) {
      this.$router.push({name: "DeviceUpdate",query:{guid}})
    },

    searchDeviceLogList () {
      this.currentPage = 1
      this.getDeviceLogList()
    },
    async getDeviceLogList () {
      let reqObj = {}
      reqObj.target = `/event/devicelog`;
      reqObj.method = "get";
      reqObj.params = {
        "companyGuid": this.companySearchGuid ? this.companySearchGuid : '0',
        "siteGuid": this.siteSearchGuid ? this.siteSearchGuid : '0',
        "mapGuid": this.mapSearchGuid ? this.mapSearchGuid : '0',
        "deviceID": this.deviceSearchID ? this.deviceSearchID : '',
        "eventType": this.eventSearchValue ? this.eventSearchValue : '',
        "offset": this.currentPage - 1,
        "limit": this.limit
      };
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.deviceLogList = result.msg.retData.content
        this.totalCount = result.msg.retData.totalElements
      }
    },


    chgCurrentPage (num) {
      console.log(num)
      this.currentPage = num
      this.getDeviceLogList()
    },

    async getCompanyListAll () {
      let reqObj = {}
      reqObj.target = "/company/listall";
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      this.companyList = []
      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.companyList = result.msg.retData
      }
    },

    async getSiteListAll () {
      let reqObj = {}
      reqObj.target = `/site/list/${this.companySearchGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      this.siteList = []
      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.siteList = result.msg.retData
      }
    },

    async getMapListAll () {
      let reqObj = {}
      reqObj.target = `/map/list/${this.siteSearchGuid}`;
      reqObj.method = "get";
      reqObj.deviceGuid
      this.mapList = []
      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.mapList = result.msg.retData
      }
    },

    async getDeviceRelation () {
      let reqObj = {}
      reqObj.target = `/device/info/${this.searchDeviceGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.deviceInfo = result.msg.retData
      }
    },

    async makeSearchParams () {
      // console.log("#############################", this.$route.query.guid, this.deviceInfo.companyGuid)
      if(this.deviceInfo.companyGuid) {
        this.companySearchGuid = this.deviceInfo.companyGuid
        this.siteSearchGuid = ''
        await this.getSiteListAll()
      }
      if(this.deviceInfo.siteGuid) {
        this.siteSearchGuid = this.deviceInfo.siteGuid
        this.mapSearchGuid = ''
        await this.getMapListAll()
      }
      if(this.deviceInfo.mapGuid) {
        this.mapSearchGuid = this.deviceInfo.mapGuid
      }
      if(this.deviceInfo.deviceID) {
        this.deviceSearchID = this.deviceInfo.deviceID
      }

      if(!this.$route.query.guid) {
        if(!this.deviceInfo.companyGuid) {
          this.searchDeviceLogList()
        }
      }
      else {
        if(this.deviceInfo.companyGuid) {
          this.searchDeviceLogList()
        }
      }
    },
  },
  watch: {
    'deviceInfo': function () {
      this.makeSearchParams ()
    }

  }
}
</script>