<template lang="html">
  <div class="content-box">
    <div class="component-tab-a">
      <a @click="chgTabIndex(1)" class="tab-item cursor-pointer" :class="tabIndex === 1 ? 'active' : ''">단말기 관리</a>
      <a  v-if="getUserRole === 'master'" @click="chgTabIndex(2)" class="tab-item cursor-pointer" :class="tabIndex === 2 ? 'active' : ''">펌웨어 등록</a>
      <a @click="chgTabIndex(3)" class="tab-item cursor-pointer" :class="tabIndex === 3 ? 'active' : ''">단말기 로그</a>
    </div>

    <!-- 단말 관리 TAB START -->
    <div v-if="tabIndex === 1">
      <DeviceList></DeviceList>
    </div>
    <!-- 단말 관리 TAB END -->

    <!-- 펌웨어 관리 TAB START -->
    <div v-if="tabIndex === 2">
      <DeviceFirmware></DeviceFirmware>
    </div>
    <!-- 펌웨어 관리 TAB END -->

    <!-- 단말기 로그 TAB START -->
    <div v-if="tabIndex === 3">
      <DeviceLog :searchDeviceGuid="deviceGuid"></DeviceLog>
    </div>
    <!-- 단말기 로그 TAB END -->

  </div>
</template>

<script>
// import { requestApi } from "@/utils/api"
import { mapGetters, mapActions } from "vuex"

import DeviceList from "@/pages/setting/DeviceList"
import DeviceFirmware from "@/pages/setting//DeviceFirmware"
import DeviceLog from "@/pages/setting/DeviceLog"

export default {
  created () {
    console.log(this.$route.query.guid)
    if(this.$route.query.guid) {
      this.tabIndex = 3
      this.deviceGuid = this.$route.query.guid
    }
  },
  components: {
    DeviceList,
    DeviceFirmware,
    DeviceLog
  },
  computed: {
    ...mapGetters(["getIsOverlay","getUserRole"]),
  },
  data () {
    return {
      tabIndex: 1,
      deviceGuid: '',
    }
  },
  methods: {
    ...mapActions(["chgIsOverlay"]),
    chgTabIndex (idx) {
      this.tabIndex = idx;
    }
  }
}
</script>